(function($) {

  //Add inner img to wrapper for bg image styling
  function add_image_to_bg_wrap() {
    $('.card-img-top-wrapper, .featured-product-img-wrapper, .img-wrapper').each(function () {
        var $container = $(this),
            imgUrl = $container.find('img').prop('src'); //
        if (imgUrl) {
          $container.css('backgroundImage', 'url(' + imgUrl + ')');
        }
    });
  }

  //init
  add_image_to_bg_wrap();

  //Init after every ajax request
  $( document ).ajaxComplete(function() {
    add_image_to_bg_wrap();
  });

})(jQuery); // Fully reference jQuery after this point.