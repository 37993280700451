(function ($) {

    $('#navbarNav').on('show.bs.collapse', function (e) {
        $('.navbar-top').addClass('nav-open');
        if (!$('#navbar').hasClass('navbar-sticky')) {
            $("html, body").animate({
                scrollTop: 0
            }, "slow");
        }
    });

    $('#navbarNav').on('shown.bs.collapse', function (e) {
        $('.navbar-top').addClass('nav-opened'); //
    });

    $('#navbarNav').on('hidden.bs.collapse', function (e) {
        $('.navbar-top').removeClass('nav-open');
    });

    $('#navbarNav').on('hide.bs.collapse', function (e) {
        $('.navbar-top').removeClass('nav-opened');
    });

    var stickyTop = $('#navbar').offset().top;

    // dit glitcht op iOS mobile, omdat de "window resized" wordt als je omhoog scrollt en de iOS safari navbar toont/verwijnd of als de topbar groter/kleiner wordt gaat hij stickytop onterecht op een verkeerde hoogte zetten waardoor het menu ineens verwijnd
    //   $(window).resize(function(){
    //     stickyTop = $('#navbar').offset().top;
    //   });

    $(window).on('scroll', function () {
        if ($(window).scrollTop() >= stickyTop) {
            $('#navbar').addClass('navbar-sticky');
        } else {
            $('#navbar').removeClass('navbar-sticky');
        }
    });

})(jQuery); // Fully reference jQuery after this point.
