(function($) {

  var thisData = {};
  var products = [];
  var page = 1;

  // Get active terms
  function get_active_products () {

      products = [];

      $('.project-filter-link.active').each(function(i, obj) {
          if ($(this).data('product_id')) {
            products.push($(this).data('product_id'));
          }
      });

      return products;
  }

  // Get next or prev page number
  function page_number (el) {

		var currentPage = parseInt($('.page-numbers.current').text());

		var page = currentPage;

		if ($(el).hasClass('next')) {

			page = currentPage + 1;

		} else if ($(el).hasClass('prev')) {

			page = currentPage - 1;

		} else {

			page = parseInt($(el).text());

		}

		return page;

  }

  // Load posts with ajax function
  function ajax_load_posts (thisData, emptyContainer){

    console.log(thisData);

    $.ajax({
        url: ajaxvars.ajax_url,
        type: 'post',
        data: thisData,
        beforeSend: function() {
          if(emptyContainer) {
            $('#postContainer').empty();
          } else {
            $('#postContainer .load-posts-col').remove();
          }
          $('#postContainer').append('<div class="temp_loader"></div>');
        },
        success: function( data) {
          $('#postContainer .temp_loader').remove();
          $('#postContainer').append(data);
        }

    });
  }

  // Set data according to post type
  function get_data(post_type) {

    if (post_type == 'project') {
        thisData = {
          action: 'mw_load_projects',
          page: page,
          product_ids: products,
          post_type: post_type
        };      
    } else if (post_type == 'review') {
        thisData = {
          action: 'mw_load_reviews',
          page: page,
          post_type: post_type
        };
    }

    return thisData;

  }

  // On click event of filter attribute
  $(document).on('click', '.filter-nav-link, .filter-nav-all', function(event){

    event.preventDefault();
    var post_type = $(this).data('post_type');

    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
    } else {
      $(this).addClass('active');
    }

    if ($(this).hasClass('filter-nav-all')) {
      $('.project-filter-link.active').removeClass('active');
      products = [];
    } else {
      $('.filter-nav-all.active').removeClass('active');
      products = get_active_products();
    }

    thisData = get_data(post_type);

    ajax_load_posts(thisData, true);

  });

  // Pagination
  $(document).on('click', '.load-posts', function(e) {

    e.preventDefault();

    page = $(this).data('page') + 1;
    var post_type = $(this).data('post_type');

    thisData = get_data(post_type, page);

    products = get_active_products();

    ajax_load_posts(thisData, false);

  });

})(jQuery);